import { AnchorIcon } from "../icons/ElementIcons";
import Link from "next/link";
import { Tag } from "./Tag";
import { useRef } from "react";

export const TaggedSection = ({
  tag,
  label,
}: {
  tag?: string;
  label?: string;
}) => {
  if (!tag && !label) {
    return null;
  }

  return (
    <div className="flex items-center mt-5 gap-x-3">
      {tag && <Tag>{tag}</Tag>}
      {tag && label && (
        <span className="h-0.5 w-0.5 rounded-full bg-zinc-300 dark:bg-zinc-600" />
      )}
      {label && (
        <span className="font-mono text-xs text-zinc-400">{label}</span>
      )}
    </div>
  );
};

export function Anchor({
  id,
  children,
}: {
  id?: string;
  children: JSX.Element;
}) {
  return (
    <Link href={`#${id}`} scroll={false}>
      <div className="flex flex-row items-center mb-1 overflow-x-auto overflow-y-hidden no-underline group text-inherit">
        <span>{children}</span>
        <AnchorIcon className="hidden w-6 h-6 my-auto ml-2 transition opacity-0 lg:block stroke-zinc-500 dark:stroke-zinc-400 group-hover:opacity-100" />
      </div>
    </Link>
  );
}

export const Heading = ({
  level = 2,
  children,
  tag,
  label,
  anchor = true,
  id = "",
  ...props
}: {
  level: number;
  children: JSX.Element;
  tag?: string;
  label?: string;
  anchor?: boolean;
  id: string;
  props: any;
}) => {
  const Component: any = `h${level}`;
  const ref = useRef<HTMLDivElement>(null);
  return (
    <>
      <TaggedSection tag={tag} label={label} />
      <Component
        ref={ref}
        className={tag || label ? "mt-2 scroll-mt-32" : "scroll-mt-24"}
        id={id}
        {...props}
      >
        {anchor ? (
          <div className="not-prose">
            <Anchor id={id}>{children}</Anchor>
          </div>
        ) : (
          children
        )}
      </Component>
    </>
  );
};
