import Head from "next/head";
import { constants } from "constants.mjs";
import { useRouter } from "next/router";

export const MetaHead = ({
  pageProps,
  finTitle,
  finDesc,
  canonicalUrl,
  previewImgUrl,
}: any) => {
  let router = useRouter();

  return (
    <Head>
      {router.pathname === "/" ? (
        <title>Komodo Platform Overview | Komodo Documentation</title>
      ) : (
        <title>{finTitle}</title>
      )}
      <meta name="description" content={finDesc} />
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, minimum-scale=1"
      />

      {/* <link rel="icon" href="/icons/icon-48x48.png" sizes="32x32" />
        <link rel="icon" href="/icons/icon-192x192.png" sizes="192x192" />
        <link rel="icon" href="/icons/icon-512x512.png" sizes="512x512" />
        <link rel="apple-touch-icon" href="/icons/icon-512x512.png" /> */}
      {/* <link
        rel="icon"
        href={constants.docsPath + "/favicon.svg"}
        type="image/svg+xml"
      /> */}
      <link
        rel="alternate icon"
        href={constants.docsPath + "/favicon.ico"}
        sizes="any"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={constants.docsPath + "/apple-touch-icon.png"}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={constants.docsPath + "/favicon-32x32.png"}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={constants.docsPath + "/favicon-16x16.png"}
      />
      {/* <link
      rel="manifest"
  href="/manifest.webmanifest"
  crossOrigin="use-credentials"
      /> */}
      <link
        rel="mask-icon"
        href={constants.docsPath + "/safari-pinned-tab.svg"}
        color="#42ba96"
      />
      <meta name="msapplication-TileColor" content="#00aba9" />
      <meta name="theme-color" content="#42ba96" />

      <link rel="canonical" href={canonicalUrl} key="canonical" />

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={finTitle} />
      <meta property="og:description" content={finDesc} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:site_name" content="Komodo Platform Documentation" />
      <meta property="og:image" content={previewImgUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={finTitle} />
      <meta name="twitter:description" content={finDesc} />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:site" content="@komodoplatform" />
      <meta name="twitter:image" content={previewImgUrl}></meta>
    </Head>
  );
};
