import { useEffect, useState } from "react";

import clsx from "clsx";
import { debounce } from "@/shared-functions/debounce";
import { scrollToSection } from "@/shared-functions/scrollToSection";

export const ScrollToTop = () => {
  const [showScroller, setShowScroller] = useState(false);
  const verifyScrollingCriteria = () => {
    if (window.scrollY >= 20) setShowScroller(true);
    else setShowScroller(false);
  };
  const debouncedOnScroll = debounce(verifyScrollingCriteria);
  useEffect(() => {
    window.addEventListener("scroll", debouncedOnScroll, {
      passive: true,
    });
    return () => {
      window.removeEventListener("scroll", debouncedOnScroll, true);
    };
  }, []);
  return (
    <div
      onClick={() => scrollToSection({ top: 0, left: 0 })}
      role="navigation"
      aria-label="Scroll to top of the page"
      className={clsx(
        "-sm:hidden ease-in duration-300 bg-white dark:bg-brand-dark fixed opacity-50 hover:opacity-100 bottom-[-10%] right-5 z-10 w-[50px] h-[50px] group flex items-center justify-center rounded-full border-2 border-sky-500 cursor-pointer text-sky-500 font-bold",
        { "bottom-[15px]": showScroller }
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.5 15.75l7.5-7.5 7.5 7.5"
        />
      </svg>
    </div>
  );
};
