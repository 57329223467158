import { Dispatch, SetStateAction } from "react";
import { removedWords } from "./data/removedSearchWords.mjs";
import searchIndex from "./data/searchIndex.json";

// TODO: provide ranking hierarchy for search results
export interface SearchResult {
  titleRankingScore: number;
  contentRankingScore: number;
  exactWordMatch: number;
  searchTitle: string;
  docsPageTitle: string;
  path: string;
}

export type ClusterType = SearchResult & { clusterName: string };
export const searchHandler = async ({
  searchTerm,
  setSearchResultsReadonly,
}: {
  searchTerm: string;
  setSearchResultsReadonly: any;
}) => {
  let matchedSearch: SearchResult[] = [];
  const searchTerms = searchTerm
    .trim()
    .toLowerCase()
    .split(" ")
    .filter((eachWord) => {
      if (!removedWords.some((removedWord) => removedWord === eachWord))
        return eachWord;
    });
  if (!searchTerms[0]) {
    setSearchResultsReadonly([]);
    return;
  } else
    Object.values(searchIndex).forEach((blogData) => {
      let titleRankingScore = 0;
      let contentRankingScore = 0;
      let exactWordMatch = 0;
      searchTerms.forEach((keyword) => {
        if (keyword)
          if (
            blogData.searchTitle
              .toLocaleLowerCase()
              .split(" ")
              .some((titleWord) => titleWord === keyword)
          ) {
            titleRankingScore += 2;
          } else if (
            blogData.searchTitle.toLocaleLowerCase().includes(keyword)
          ) {
            titleRankingScore++;
          }
        Object.keys(blogData.content).forEach((word) => {
          if (word === keyword) {
            exactWordMatch += 1;
          }
          if (word.includes(keyword)) {
            let index = word as keyof typeof blogData.content;
            contentRankingScore += blogData.content[index];
          }
        });
      });
      // add blogs that meet at least one criteria (score > 1)
      (titleRankingScore || contentRankingScore) &&
        matchedSearch.push({
          titleRankingScore,
          contentRankingScore,
          exactWordMatch,
          searchTitle: blogData.searchTitle,
          docsPageTitle: blogData.docsPageTitle,
          path: blogData.path,
        });
    });
  // sort search by ranks
  matchedSearch
    .sort((a, b) => {
      if (
        a.titleRankingScore +
          a.exactWordMatch -
          (b.titleRankingScore + b.exactWordMatch) !==
        0
      )
        //   if one is greater in ranking
        return (
          a.titleRankingScore +
          a.exactWordMatch -
          (b.titleRankingScore + b.exactWordMatch)
        );
      // else, if they're equally ranked
      else if (
        a.titleRankingScore +
          a.exactWordMatch -
          (b.titleRankingScore + b.exactWordMatch) ===
        0
      )
        if (a.exactWordMatch - b.exactWordMatch === 0)
          return a.contentRankingScore - b.contentRankingScore;
        else return a.exactWordMatch - b.exactWordMatch;
      else return 0;
    })
    .reverse();
  setSearchResultsReadonly(matchedSearch);
  return true;
};
