import React from "react";

const Tooltip = ({ label, dir = "top", children }) => {
  return (
    <div className="hidden lg:flex relative text-center cursor-pointer group z-[10000]">
      {children}
      <div
        className={`transition opacity-0 shadow-md w-28 bg-primaryLighter text-slate-300 text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 ${dir === "top" ? "bottom-full" : dir === "bottom" ? "-bottom-[200%]" : ""} ${dir === "left" ? "-left-[200%]" : dir === "right" ? "-right-[200%]" : ""
          }  ${dir === "top" || dir === "bottom" ? "left-1/2 -translate-x-1/2 -translate-y-[1rem] group-hover:-translate-y-[0.5rem]" : ""} ${dir === "left" || dir === "right" ? "top-1/2 -translate-y-1/2 translate-x-[3.5rem]" : ""} pointer-events-none`}
      >
        {label}
        <svg
          className="absolute left-0 w-full h-3 text-primaryLighter top-full"
          x="0px"
          y="0px"
          viewBox="0 0 255 255"
        >
          <polygon
            className={`fill-current ${dir === "top" ? "" : "hidden"}`}
            points="0,0 127.5,127.5 255,0"
          />
        </svg>
      </div>
    </div>
  );
};

export default Tooltip;
