import { CloseIcon, Logo, MenuIcon } from "../icons/ElementIcons";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { navbarNavData } from "@/data/navbar";
import { Fragment, useEffect, useState } from "react";
import { inter } from "@/styles/fonts";
import { Listbox, Transition, Dialog } from "@headlessui/react";
import { LeftSideBar } from "./LeftSideBar";
import Link from "next/link";
import NavDropdown from "./NavDropdown";
import { useRouter } from "next/router";

export const MobileNavigation = () => {
  let router = useRouter();
  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) return;

    function onRouteChange() {
      setIsOpen(false);
    }

    router.events.on("routeChangeComplete", onRouteChange);
    router.events.on("routeChangeError", onRouteChange);

    return () => {
      router.events.off("routeChangeComplete", onRouteChange);
      router.events.off("routeChangeError", onRouteChange);
    };
  }, [router, isOpen]);

  return (
    <div className={`mr-6 relative flex lg:hidden `}>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className="relative"
        aria-label="Open navigation"
      >
        <MenuIcon className="w-6 h-6 stroke-slate-500" />
      </button>
      <Dialog
        open={isOpen}
        onClose={setIsOpen}
        className="fixed inset-0 z-[10000] flex items-start  bg-slate-900/50 pr-10 backdrop-blur lg:hidden"
        aria-label="Navigation"
      >
        <Dialog.Panel
          className={`relative max-h-full overflow-y-auto w-full max-w-xs bg-white px-4 pt-5 pb-5 dark:bg-brand-dark sm:px-6 ${inter.variable} font-sans`}
        >
          <div className="relative flex items-center mb-4">
            <Link href="/" className=" stroke-slate-500" aria-label="Home page">
              <Logo />
            </Link>
            <button
              type="button"
              className="absolute right-0"
              onClick={() => setIsOpen(false)}
              aria-label="Close navigation"
            >
              <CloseIcon className="w-6 h-6 stroke-slate-500" />
            </button>
          </div>
          <div className="relative flex flex-col items-start justify-start">
            <Link href="/" className="py-2">
              <span className="text-sm font-medium text-white rounded-md hover:text-gray-300 bg-opacity-20 ">
                Home
              </span>
            </Link>
            {/* {navbarNavData.map((dropDownData) => (
              <NavDropdown
                key={dropDownData.title}
                dropDownData={dropDownData}
              />
            ))} */}
            <MobileNavItems dropDownData={navbarNavData} />
          </div>
          <LeftSideBar classNaming="mt-5 bg-slate-900 rounded-lg p-4" />
        </Dialog.Panel>
      </Dialog>
    </div>
  );
};

const MobileNavItems = ({ dropDownData }: any) => {
  const [selectedDropDownElTitle, setselectedDropDownElTitle] = useState(
    dropDownData[0].title
  );

  return (
    <div className="relative w-full">
      <Listbox
        value={selectedDropDownElTitle}
        onChange={setselectedDropDownElTitle}
      >
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left text-white rounded-lg shadow-md cursor-default bg-slate-900 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selectedDropDownElTitle}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronUpDownIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute w-full bg-slate-900 py-1 mt-1 overflow-auto text-base rounded-md shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm z-[6000]">
              {dropDownData.map((dropDownDataEl: any, idx: any) => (
                <Listbox.Option
                  key={idx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-cyan-100 text-cyan-900" : "text-cyan-300"
                    }`
                  }
                  value={dropDownDataEl.title}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {dropDownDataEl.title}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-cyan-900">
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      <div className="mt-2 rounded-lg shadow-md bg-slate-700">
        {dropDownData
          .filter(
            (dropDownDataEl: any) =>
              selectedDropDownElTitle === dropDownDataEl.title
          )[0]
          .items.map((item: any, index: number) => {
            return (
              <Link key={index} href={item.link}>
                <button className="block w-full py-2 pl-3 text-left text-white cursor-pointer focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  {item.title}
                </button>
              </Link>
            );
          })}
      </div>
    </div>
  );
};
