import Head from "next/head";

export default function StructuredData({ data , customKey}) {
  return (
    <Head>
      <script
        key= {customKey}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      />
    </Head>
  );
}
