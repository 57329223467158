import { useEffect, useState } from "react";
import { DarkIcon, LightIcon } from "../icons/ElementIcons";

export function ThemeSelector() {
  const [selectedTheme, setSelectedTheme] = useState<"light" | "dark">();

  useEffect(() => {
    if (selectedTheme)
      document.documentElement.setAttribute("data-theme", selectedTheme);
    else
      setSelectedTheme(
        document.documentElement.getAttribute("data-theme") as "light" | "dark"
      );
  }, [selectedTheme]);

  useEffect(() => {
    const handler = () => setSelectedTheme(window.localStorage.theme ?? "dark");

    window.addEventListener("storage", handler);

    return () => window.removeEventListener("storage", handler);
  }, []);

  return (
    <div
      aria-label={selectedTheme + "theme"}
      role="button"
      onClick={() =>
        setSelectedTheme((theme) => (theme === "light" ? "dark" : "light"))
      }
      className="flex select-none cursor-pointer h-6 w-6 items-center justify-center rounded-lg shadow-md shadow-black/5 ring-1 ring-black/5 dark:bg-slate-700 dark:ring-inset dark:ring-white/5"
    >
      <label className="sr-only">Theme</label>
      <LightIcon className="hidden h-4 w-4 fill-sky-400 [[data-theme=light]_&]:block" />
      <DarkIcon className="hidden h-4 w-4 fill-sky-400 [[data-theme=dark]_&]:block" />
    </div>
  );
}
