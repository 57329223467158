import {
  AnchorHTMLAttributes,
  Fragment,
  useState,
  useRef,
  useEffect,
} from "react";
import { Dialog, DialogOverlay, DialogContent } from "@reach/dialog";

import ExportedImage from "next-image-export-optimizer";
import { Heading } from "./Heading";
import { InfoIcon } from "../icons/ElementIcons";
import Link from "next/link";
import { OptimizedImageProps } from "./types";
import clsx from "clsx";

export { CodeGroup, UseInjectScript, Code as code, Pre as pre } from "./Code";
export { TocForIndex } from "./TocForIndex";

export const Note = ({
  children,
  type = "info",
  classNaming,
}: {
  children: JSX.Element;
  type: "error" | "info" | "warning";
  classNaming?: string;
}) => {
  return (
    <div
      className={clsx(
        "my-6 flex gap-2.5 rounded-2xl border p-4 leading-6",
        {
          "bg-primary-50/50 text-primary-900 dark:text-primary-200 dark:bg-primary-500/5":
            type === "info",
        },
        {
          "bg-[#ef5350] text-white dark:bg-[#c62828] prose-a:text-white":
            type === "error",
        },
        {
          "bg-[#ffb74d] dark:bg-[#461414] dark:text-white ": type === "warning",
        },
        classNaming
      )}
    >
      <InfoIcon
        className={clsx(
          "mt-1 h-4 w-4 flex-none stroke-white dark:fill-primary-200/20 dark:stroke-primary-200",
          {
            "fill-primary-500": type === "info",
          },
          {
            "fill-[#c62828]": type === "error",
          },
          {
            "fill-[#461414]": type === "warning",
          }
        )}
      />
      <div className="[&>:first-child]:mt-0 [&>:last-child]:mb-0">
        {children}
      </div>
    </div>
  );
};

export function Row({ children }: { children: JSX.Element }) {
  return (
    <div className="grid items-start grid-cols-1 gap-x-16 gap-y-10 xl:max-w-none xl:grid-cols-2">
      {children}
    </div>
  );
}

export function Col({
  children,
  sticky = false,
}: {
  children: JSX.Element;
  sticky: boolean;
}) {
  return (
    <div
      className={clsx(
        "[&>:first-child]:mt-0 [&>:last-child]:mb-0",
        sticky && "xl:sticky xl:top-24"
      )}
    >
      {children}
    </div>
  );
}

const Anchor = (
  href: string,
  props: AnchorHTMLAttributes<HTMLAnchorElement>
) => {
  <Link href={href} {...props}></Link>;
};

export const h2 = (props: any) => {
  return (
    <Heading {...props} level={2}>
      {props.children}
    </Heading>
  );
};
export const h3 = (props: any) => {
  return (
    <Heading {...props} level={3}>
      {props.children}
    </Heading>
  );
};
export const h4 = (props: any) => {
  return (
    <Heading {...props} level={4}>
      {props.children}
    </Heading>
  );
};
export const h5 = (props: any) => {
  return (
    <Heading {...props} level={5}>
      {props.children}
    </Heading>
  );
};
export const h6 = (props: any) => {
  return (
    <Heading {...props} level={6}>
      {props.children}
    </Heading>
  );
};

export const OptimizedImage = ({
  src,
  alt,
  title,
  style,
  classNaming,
  noZoom,
  otherProps,
}: OptimizedImageProps) => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeModalIfClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", closeModalIfClickOutside);

    return () => {
      document.removeEventListener("mousedown", closeModalIfClickOutside);
    };
  }, []);
  return (
    <>
      <ExportedImage
        loading="lazy"
        src={src}
        alt={alt || title || "Image in Komodo docs"}
        placeholder="empty"
        title={title || alt || "Image in Komodo docs"}
        className={classNaming ? classNaming : "w-1/2 mx-auto"}
        onClick={openModal}
        style={{
          //...
          ...style,
        }}
        {...otherProps}
      />
      {!noZoom && (
        <DialogOverlay
          isOpen={isOpen}
          onDismiss={closeModal}
          dangerouslyBypassFocusLock
        >
          <DialogContent className="">
            <div className="fixed inset-0 bg-black bg-opacity-50" />
            <div className="fixed inset-0">
              <div className="flex items-center justify-center min-h-full text-center">
                <div
                  ref={ref}
                  className="h-[90vh] xs:h-[98vh] w-auto transform bg-slate-800 align-middle shadow-xl transition-all"
                >
                  <ExportedImage
                    loading="lazy"
                    src={src}
                    alt={alt || title || "Image in Komodo docs"}
                    placeholder="empty"
                    title={title || alt || "Image in Komodo docs"}
                    className={classNaming + " h-full w-[auto] mx-auto"}
                    onClick={closeModal}
                    style={{
                      ...style,
                    }}
                    {...otherProps}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
        </DialogOverlay>
      )}
    </>
  );
};

export const ThemeSwitchImage = ({
  onLightMode,
  onDarkMode,
}: {
  onLightMode: OptimizedImageProps;
  onDarkMode: OptimizedImageProps;
}) => {
  return (
    <>
      <OptimizedImage
        {...onLightMode}
        classNaming={onDarkMode?.classNaming + " hidden dark:block"}
      />
      <OptimizedImage
        {...onDarkMode}
        classNaming={onDarkMode?.classNaming + " dark:hidden"}
      />
    </>
  );
};

export const CollapsibleSection = ({
  children,
  collapsedText = "Show",
  expandedText = "Hide",
}: {
  children: JSX.Element;
  collapsedText?: string;
  expandedText?: string;
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <button
        aria-label="collapsible section"
        onClick={() => setVisible(!visible)}
        className="block mb-3 dark:bg-brand-green py-0.5 px-2 rounded dark:text-slate-200 bg-brand-light text-white hover:brand-green/40"
      >
        {visible ? expandedText : collapsedText}
      </button>
      {visible ? children : null}
    </div>
  );
};

export const BulletPoints = ({
  imageProps,
  title,
  desc,
}: {
  imageProps: OptimizedImageProps;
  title: string;
  desc: string | JSX.Element;
}) => {
  return (
    <div className="relative flex flex-col items-center px-3 py-2 mt-5 border md:flex-row rounded-xl border-slate-200 dark:border-brand-light ">
      {/* <div> */}
      <OptimizedImage
        {...imageProps}
        classNaming={imageProps.classNaming ? imageProps.classNaming : ""}
      />
      {/* </div> */}
      <div className="mx-5">
        <h3 className="my-2">{title}</h3>
        {typeof desc === "string" ? <p className="">{desc}</p> : desc}
      </div>
    </div>
  );
};

export const DevComment = ({ children }: { children: JSX.Element }) => {
  return null;
};
