import React, { useEffect, useRef, useState } from "react";

import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { LeftSideBarCategory } from "./LeftSideBarCategory";
import Tooltip from "./Tooltip";
import clsx from "clsx";
import { useHandleNavigation } from "@/store/navigation";
import { useRouter } from "next/router";

// interface SidebarProps {
//   links: { label: string; to: string }[];
//   height: { label: string; to: string }[];
// }

export const LeftSideBar: any = ({
  isSidebarCollapsed,
  setIsSidebarCollapsed,
  activeSection,
  classNaming,
}: any) => {
  const { navigationData } = useHandleNavigation();
  const [expandedSection, setExpandedSection] = useState<string | null>(null);
  //const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [prevActiveSection, setPrevActiveSection] = useState(null);
  const router = useRouter();
  const currentPath = router.pathname;

  function stripSlashFromEnd(str: string) {
    return str.replace(/\/+$/, "");
  }

  useEffect(() => {
    navigationData.forEach((obj) => {
      let shouldExpandObj = obj.links.some((item) => {
        return stripSlashFromEnd(item.href) === stripSlashFromEnd(currentPath);
      });
      if (shouldExpandObj) {
        setExpandedSection(obj.title);
      }
    });
  }, [navigationData, currentPath]);

  const toggleSection = (sectionTitle: string) => {
    if (expandedSection === sectionTitle) {
      setExpandedSection(null); // collapse the section if it's already expanded
    } else {
      setExpandedSection(sectionTitle); // expand the new section
    }
  };

  useEffect(() => {
    //const hash = router.asPath.split("#")[1];
    if (activeSection && prevActiveSection) {
      const element = document.getElementById(`${prevActiveSection}`);
      if (element) {
        // Scroll to the element smoothly
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [isSidebarCollapsed]);

  const toggleNavCollapse = () => {
    setPrevActiveSection(activeSection);
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };
  //const classNameProp = pageProps?.classNaming || "";

  // if (!navigationData.length) return null;
  const [activeLinkEl, setActiveLinkEl] = useState<HTMLAnchorElement | null>(
    null
  ); // state to store active link element

  const [timePassed, setTimePassed] = useState(false); // State to toggle visibility of the list
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimePassed(true);
    }, 1000); // 1 second

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (timePassed) {
      // TODO: use another metric instead of timepassed
      // console.log(activeLinkEl?.parentElement?.offsetTop);

      // console.log("activeLinkEl", activeLinkEl?.offsetTop);
      //test  in http://localhost:3000/en/docs/antara/tutorials/pegs-module-creator-tutorial/#tutorial-overview
      let div = document.getElementById("sidebar-scroll-div");
      // console.log("div", div ? div.scrollHeight : "");
      // console.log("div", div ? div.clientHeight : "");
      let nav = document.getElementById("sidebar-nav");
      // console.log("nav", nav ? nav.scrollHeight : "");
      // console.log("nav", nav ? nav.clientHeight : "");

      let leftSideBarHeight = div?.clientHeight;
      let activeElTop = activeLinkEl?.parentElement?.offsetTop;
      let sidebarFullHeight = nav?.scrollHeight;
      // console.log("leftSideBarHeight", leftSideBarHeight);
      // console.log("activeElTop", activeElTop);
      // console.log("sidebarFullHeight", sidebarFullHeight);
      if (leftSideBarHeight && activeElTop && sidebarFullHeight && div) {
        //console.log("div.scrollTop", activeElTop - leftSideBarHeight);
        div.scrollTop = activeElTop - leftSideBarHeight + leftSideBarHeight / 2;
      }
    }
  }, [timePassed, activeLinkEl]);

  return (
    <div
      className={`relative lg:sticky lg:top-[7rem] z-[1100] ${
        classNaming ? classNaming : ""
      }`}
    >
      <div
        onClick={toggleNavCollapse}
        className="relative cursor-pointer flex items-center z-[100] justify-between"
      >
        <div
          className={`${
            isSidebarCollapsed
              ? "absolute right-[-15px] top-[0px]"
              : "absolute right-[-12px] top-[0px]"
          }`}
        >
          <Tooltip
            label={isSidebarCollapsed ? "Open Sidebar" : "Collapse Sidebar"}
            dir="right"
          >
            <ChevronLeftIcon
              className={`transition-transform  h-8 w-8 rounded-lg border-[#026782] border-2 bg-slate-800 hover:bg-slate-600 text-white ${
                isSidebarCollapsed ? "rotate-180" : "rotate-0"
              }`}
            />
          </Tooltip>
        </div>
      </div>
      <div
        className={`relative lg:-ml-0.5 lg:h-[calc(100vh-7.5rem)] overflow-x-hidden py-0 lg:pl-0.5 ${
          isSidebarCollapsed
            ? "border-r-2 border-slate-700 w-0"
            : "w-full lg:w-64 xl:w-72"
        }`}
        id="sidebar-scroll-div"
      >
        <div className={`${isSidebarCollapsed ? "hidden" : "block"}`}>
          <nav
            className={clsx(
              "text-base lg:text-sm h-full border-slate-700 lg:border-r-2 pr-4 relative",
              "lg:overflow-y-auto"
            )}
            id="sidebar-nav"
          >
            <ul role="list" className="pb-4 space-y-4">
              {navigationData.map((section) => (
                <LeftSideBarCategory
                  section={section}
                  key={section.title}
                  isExpanded={expandedSection === section.title}
                  onToggle={() => toggleSection(section.title)}
                  activeLinkEl={activeLinkEl}
                  setActiveLinkEl={setActiveLinkEl}
                />
              ))}
            </ul>
          </nav>
        </div>
      </div>{" "}
    </div>
  );
};
