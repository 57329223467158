import "@/styles/globals.css";

import * as mdxComponents from "@/components/mdx";

import type { AppProps } from "next/app";
import { CustomLink } from "@/components/override-mdx/Link";
import Head from "next/head";
import { Layout } from "@/components/shared/Layout";
import { MDXProvider } from "@mdx-js/react";
import { MetaHead } from "@/components/shared/MetaHead";
import { RecoilRoot } from "recoil";
import { constants } from "constants.mjs";
import { useRouter } from "next/router";

const customComponents = { a: CustomLink, ...mdxComponents };

export default function App({ Component, pageProps }: AppProps) {
  let router = useRouter();
  const descPreset =
    "After years of innovation and development, Komodo's multi-chain architecture overcomes the challenges faced by other smart-contract platforms and introduces a cross-chain dex that supports 99% cryptocurrencies in existence";
  const titlePreset = "Komodo Documentation";
  const finTitle = pageProps.title
    ? pageProps.title + " | " + titlePreset
    : pageProps.exported_h1
    ? pageProps.exported_h1 + " | " + titlePreset
    : titlePreset;
  const finDesc = pageProps.description || descPreset;
  const canonicalUrl = `${constants.domain}${constants.docsPath}${router.pathname}/`;
  let previewImgUrl;
  if (process.env.CFP == "yes") {
    previewImgUrl = `${constants.docsPath}/preview-images${
      "/pre" + router.pathname.split("/").join("-") + ".jpg"
    }`;
  } else {
    previewImgUrl = `${constants.domain}${constants.docsPath}/preview-images${
      "/pre" + router.pathname.split("/").join("-") + ".jpg"
    }`;
  }
  return (
    <RecoilRoot>
      <MDXProvider components={customComponents as any}>
        <MetaHead
          pageProps={pageProps}
          finTitle={finTitle}
          finDesc={finDesc}
          canonicalUrl={canonicalUrl}
          previewImgUrl={previewImgUrl}
        />
        <Layout
          pageProps={pageProps}
          finTitle={finTitle}
          finDesc={finDesc}
          canonicalUrl={canonicalUrl}
          previewImgUrl={previewImgUrl}
        >
          <Component {...pageProps} />
        </Layout>
      </MDXProvider>
    </RecoilRoot>
  );
}
