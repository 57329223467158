import { Button2 } from "@/components/shared/Button";
import ExportedImage from "next-image-export-optimizer";
import blurCyanImage from "@/public/images/docs/blur-cyan.png";
import blurIndigoImage from "@/public/images/docs/blur-indigo.png";
import { lexend } from "@/styles/fonts";

export const Hero = () => {
  return (
    <div className="relative z-10 overflow-hidden bg-brand-dark">
      <div className="py-16 sm:px-2 lg:py-20 lg:px-0">
        <ExportedImage
          className="absolute -bottom-1/4 w-[750px] h-auto -right-1/4 md:left-1/3 md:top-20 opacity-40"
          src={blurCyanImage}
          role="img"
          alt="presentation"
          placeholder="empty"
          priority
        />
        <ExportedImage
          className="absolute w-[400px] h-auto top-1 -left-44"
          src={blurIndigoImage}
          role="img"
          alt="presentation"
          placeholder="empty"
          priority
        />
        <div className="max-w-3xl px-4 mx-auto lg:max-w-8xl lg:px-8 xl:px-12">
          <div className="text-left lg:text-left xs:text-center">
            <div className="relative">
              <h1
                className={`mb-6 text-4xl tracking-tight text-transparent md:text-5xl bg-gradient-to-r from-indigo-200 via-sky-400 to-indigo-200 bg-clip-text font-display ${lexend.variable}`}
              >
                {`Welcome to Komodo's Developer Documentation`}
              </h1>
              <p className="mb-8 text-xl tracking-tight xs:px-4 text-slate-400 sm:text-lg lg:px-0">
                {`Komodo SDK is an all-in-one solution for building, deploying, and managing blockchain and DeFi (d)applications. It includes the Komodo DeFi Framework, Komodo Core Protocol and the Komodo GUI/DAPP Suite, which make up a comprehensive set of tools, APIs, and libraries to help you create your own blockchain-based products`}
              </p>
              <div className="flex justify-start gap-4 xs:justify-center lg:justify-start">
                <Button2
                  arrow="right"
                  href="/start-here/core-technology-discussions/komodo-sdk/"
                  classNaming="bg-gradient-to-r from-secondary-500 to-primary-900 text-white hover:bg-gradient-to-r hover:from-secondary-900 hover:to-primary-900 hover:primary-200"
                >
                  Get started
                </Button2>
                <Button2
                  href="https://github.com/KomodoPlatform/komodo-docs-mdx/blob/dev/CONTRIBUTION_GUIDE.md"
                  classNaming="bg-zinc-800/40 text-zinc-400 ring-1 ring-inset ring-zinc-800 hover:bg-zinc-800 "
                  variant="secondary"
                >
                  Contribute
                </Button2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
