import { BreadcrumbList, WithContext } from "schema-dts";

import Link from "next/link";
import StructuredData from "./StructuredData";
import { constants } from "../../../constants.mjs";
import sidebarData from "@/data/sidebar.json";
import { useRouter } from "next/router";

export const Breadcrumbs = ({ pageProps }: any) => {
  const router = useRouter();
  const path = router.pathname;
  const currentPath = path == "/" ? path : path + "/";
  const pathArray = path.split("/");
  let pathSlugArray: any = [];
  let curPath = "";
  for (let slug of pathArray) {
    curPath = curPath + slug + "/";
    //let breadCrumb = pageProps.breadcrumb?pageProps.breadcrumb:pageProps.title;

    pathSlugArray.push([
      curPath,
      slug
        .split("-")
        .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
        .join(" ")
        .split("_")
        .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
        .join(" "),
    ]);
  }

  pathSlugArray =
    path == "/" ? [["/", "Komodo Documentation Home"]] : pathSlugArray;

  pathSlugArray[pathSlugArray.length - 1] = [
    currentPath,
    findTitleForCurrentPath(sidebarData, currentPath),
  ];

  const getFilePath = (pathSlugArray: any) => {
    let content = [];
    for (let [index, arr] of pathSlugArray.entries()) {
      if (index !== 0) {
        content.push(
          index == pathSlugArray.length - 1 ? (
            <li
              className="py-2 border-b dark:bg-slate-800/80 border-slate-500"
              key={arr[0]}
            >
              <Link href={arr[0]} aria-current="page">
                {arr[1]}
              </Link>
            </li>
          ) : (
            <li
              className="py-2 border-b dark:bg-slate-800/80 border-slate-500"
              key={arr[0]}
            >
              <Link href={arr[0]}>{arr[1]}</Link>
            </li>
          )
        );
      }
    }
    return content;
  };

  function findTitleForCurrentPath(sidebarData: any, currentPath: any) {
    for (const key in sidebarData) {
      if (sidebarData.hasOwnProperty(key)) {
        const allSections = sidebarData[key];
        const allSectionData: any = Object.values(allSections)[0];
        for (let section of allSectionData) {
          if (section.titleLink === currentPath) {
            return section.title;
          } else if (
            section.links.some((link: any) => link.href === currentPath)
          ) {
            return section.links.filter(
              (obj: any) => obj.href === currentPath
            )[0].title;
          }
        }
      }
    }
  }

  const breadcrumbData: WithContext<BreadcrumbList> = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
  };
  let itemListElement: any = [];
  for (let [index, arr] of pathSlugArray.entries()) {
    let URL = constants.domain + constants.docsPath + arr[0];
    URL = URL[URL.length - 1] === "/" ? URL.slice(0, -1) : URL;
    let name = arr[0] == "/" ? "Komodo Documentation Home" : arr[1];
    let listElement = {
      "@type": "ListItem",
      position: index + 1,
      name: name,
      item: URL,
    };
    itemListElement.push(listElement);
  }
  breadcrumbData["itemListElement"] = itemListElement;
  return (
    <div
      className="items-center inline-block py-2 my-2 mr-2 text-white breadcrumb-nav"
      aria-label="Breadcrumb"
    >
      <ol>
        <li className="dark:bg-slate-800/80 py-2 pl-[0.75rem] border-b border-slate-500">
          <Link href="/" aria-label="Navigate to Home">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          </Link>
        </li>
        {path == "/" ? "" : getFilePath(pathSlugArray)}
      </ol>
      <StructuredData
        data={breadcrumbData}
        customKey={"breadcrumb-structured-data"}
      />
    </div>
  );
};
