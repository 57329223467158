import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

import { MM2SettingsState } from "@/store/code"; // Adjust the import path as necessary
import { useCustomValuesInMM2Commands } from "@/store/code";
import { useRecoilState } from "recoil";

export const SettingsDialog = ({ isDialogOpen = false, setIsDialogOpen }) => {
  const defaultValues = {
    Userpass: "RPC_UserP@SSW0RD",
    Port: "7783",
    Host: "127.0.0.1",
  };
  // const { custom_mm2_values, setCustomMM2Values } =
  //   useCustomValuesInMM2Commands();

  const emptyValues = {
    Userpass: "",
    Port: "",
    Host: "",
  };
  const [mm2Settings, setMM2Settings] = useRecoilState(MM2SettingsState);

  // Initialize inputValues state with mm2Settings from Recoil
  const [inputValues, setInputValues] = useState(mm2Settings);
  // Update setInputValuesInDocs function to also update the Recoil state
  const setInputValuesInDocs = () => {
    setMM2Settings(inputValues); // Persist the current input values in global state
    setIsDialogOpen(false); // Optionally close the dialog
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const resetInputValues = () => {
    setInputValues(emptyValues);
  };
  return (
    <>
      <Transition appear show={isDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsDialogOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full xs:max-w-md max-w-[308px] transform overflow-hidden rounded-2xl bg-primaryLighter p-5 text-left align-middle shadow-xl transition-all text-white">
                  <div>
                    <div className="flex flex-row justify-end items-center">
                      <label htmlFor="Userpass">Userpass:</label>
                      <input
                        className="bg-transparent ml-4 rounded my-2 w-[70%] xs:w-[80%] inline-flex justify-center border-2 border-sky-700 bg-slate-800 px-4 py-2 text-lg font-medium text-white hover:bg-slate-700 focus:outline-none focus-visible:ring-1 focus-visible:ring-blue-500 "
                        type="text"
                        value={inputValues.Userpass}
                        name="Userpass"
                        placeholder={defaultValues.Userpass}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="flex flex-row justify-end items-center">
                      <label htmlFor="Port">Port:</label>
                      <input
                        className="bg-transparent ml-4 rounded my-2 w-[70%] xs:w-[80%] inline-flex justify-center border-2 border-sky-700 bg-slate-800 px-4 py-2 text-lg font-medium text-white hover:bg-slate-700 focus:outline-none focus-visible:ring-1 focus-visible:ring-blue-500 "
                        type="text"
                        value={inputValues.Port}
                        name="Port"
                        placeholder={defaultValues.Port}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="flex flex-row justify-end items-center">
                      <label htmlFor="Host">Host:</label>
                      <input
                        className="bg-transparent ml-4 rounded my-2 w-[70%] xs:w-[80%] inline-flex justify-center border-2 border-sky-700 bg-slate-800 px-4 py-2 text-lg font-medium text-white hover:bg-slate-700 focus:outline-none focus-visible:ring-1 focus-visible:ring-blue-500 "
                        type="text"
                        value={inputValues.Host}
                        name="Host"
                        placeholder={defaultValues.Host}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-around items-center text-center gap-2 pt-4">
                    <button
                      className=" w-full p-2 text-lg border-2 rounded-lg bg-rose-700 text-white font-bold border-sky-700   hover:bg-red-600"
                      onClick={resetInputValues}
                    >
                      Reset
                    </button>
                    <button
                      className="w-full p-2 font-bold text-lg border-2 rounded-lg  bg-emerald-600	text-white border-sky-700  hover:bg-green-600"
                      onClick={setInputValuesInDocs}
                    >
                      Set
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
