import Link from "next/link";
import sidebarData from "@/data/sidebar.json";
import { useRouter } from "next/router";

export const  TocForIndex = ()=>{
  const router = useRouter();
  const currentPath = router.pathname + "/";
  function findLinksForCurrentPath(sidebarData: any, currentPath: any) {
    for (let key in sidebarData) {
      for (let path in sidebarData[key]) {
        const sections = sidebarData[key][path];
        for (let section of sections) {
          if (
            section.titleLink === currentPath ||
            section.links.some((link: any) => link.href === currentPath)
          ) {
            return section.links.filter((obj: any) => obj.href !== currentPath);
          }
        }
      }
    }
    return [];
  }
  const linksArray = findLinksForCurrentPath(sidebarData, currentPath);
  return (
    <div>
      {linksArray.map((item: any) => (
        <li key={item.href}>
          <Link href={item.href}>{item.title}</Link>
        </li>
      ))}
    </div>
  );
}
