export const removedWords = [
  "he",
  "she",
  "they",
  "that",
  "this",
  "these",
  "those",
  "can",
  "could",
  "will",
  "would",
  "should",
  "might",
  "must",
  "have",
  "had",
  "been",
  "being",
  "them",
  "their",
  "our",
  "his",
  "her",
  "its",
  "who",
  "whom",
  "with",
  "without",
  "from",
  "into",
  "onto",
  "over",
  "under",
  "about",
  "after",
  "before",
  "by",
  "during",
  "except",
  "for",
  "in",
  "like",
  "of",
  "out",
  "through",
  "till",
  "until",
  "upon",
  "are",
  "was",
  "were",
  "what",
  "when",
  "where",
  "why",
  "how",
  "there",
  "here",
  "your",
  "more",
  "most",
  "some",
  "any",
  "each",
  "few",
  "many",
  "such",
  "which",
  "than",
  "all",
  "both",
  "half",
  "either",
  "neither",
  "other",
  "another",
  "much",
  "little",
  "less",
  "least",
  "only",
  "own",
  "same",
  "so",
  "too",
  "very",
  "may",
  "be",
  "do",
  "daren't",
  "needn't",
  "whose",
  "whether",
  "while",
  "although",
  "because",
  "since",
  "though",
  "unless",
  "whereas",
  "wherever",
  "as",
  "then",
  "once",
  "now",
  "always",
  "never",
  "often",
  "sometimes",
  "together",
  "well",
  "almost",
  "enough",
  "even",
  "not",
  "quite",
  "rather",
  "tomorrow",
  "yesterday",
  "north",
  "south",
  "east",
  "west",
  "please",
  "yes",
  "no",
  "certainly",
  "surely",
  "the",
  "a",
  "an",
  "and",
  "am",
  "you",
  "i",
  "to",
  "if",
  "off",
  "me",
  "my",
  "on",
  "it",
  "is",
  "at",
  "we",
  "has",
  "but",
  "or",
  "up",
];