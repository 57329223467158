import { useEffect, useRef, Fragment, useState } from "react";
import { Dialog, DialogOverlay, DialogContent } from "@reach/dialog";
// import "@reach/dialog/styles.css";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import ExportedImage from "next-image-export-optimizer";
import { User } from "@/components/mdx/types";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { constants } from "constants.mjs";
import { humanizeTime } from "@/shared-functions/timeAgo";
import linkedin from "../../../public/svg/linkedin.svg";
import twitter from "../../../public/svg/twitter.svg";
import { inter } from "@/styles/fonts";

export const Contributors = ({
  route,
  lastContributor,
  allContributors,
  fileData_route,
}: any) => {
  let [isContributorsOpen, setIsContributorsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeModalIfClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", closeModalIfClickOutside);

    return () => {
      document.removeEventListener("mousedown", closeModalIfClickOutside);
    };
  }, []);

  function closeModal() {
    setIsContributorsOpen(false);
  }
  function openModal() {
    setIsContributorsOpen(true);
  }
  return fileData_route ? (
    <div
      className={`flex flex-col items-center justify-between w-full my-4 overflow-x-hidden sm:flex-row`}
    >
      <div className="flex flex-row items-center py-4 sm:py-0 sm:max-w-[60%] ">
        <ExportedImage
          className="w-10 h-10 rounded-[50%]"
          src={`${constants.docsPath}/images/docs/authors/${lastContributor.image}`}
          alt="latest contributor to this doc"
          width={40}
          height={40}
          placeholder="empty"
          priority
        />
        {fileData_route.dateModified && (
          <div className="flex flex-wrap sm:flex-row">
            <p className="flex flex-row items-center ml-2 text-lg text-zinc-600 dark:text-zinc-400">
              Last Edit:
            </p>

            <a
              href={`https://github.com/${lastContributor.username}`}
              className="ml-2 text-blue-500 break-all hover:text-blue-300"
              target="_blank"
              rel="noreferrer"
            >
              <span className="underline">
                {"@" + lastContributor.username}
              </span>
              <span className="ml-1 no-underline">↗</span>
              {/* <ArrowUpRightIcon className="w-4 h-4 ml-1" /> */}
            </a>

            <div className="flex flex-row ml-2 text-lg text-zinc-600 dark:text-zinc-400">
              <time dateTime={fileData_route.dateModified}>
                {humanizeTime(fileData_route.dateModified)}
              </time>
            </div>
          </div>
        )}
      </div>
      <button
        className="flex flex-row items-center justify-center w-full p-2 text-sm border-2 rounded-lg sm:w-auto sm:justify-between text-sky-500 border-sky-700 dark:text-blue-400 bg-slate-800 hover:bg-slate-600"
        onClick={openModal}
      >
        See Contributors
      </button>
      <Dialog isOpen={isContributorsOpen} onDismiss={closeModal}>
        <div className="">
          <div className="fixed inset-0 bg-black bg-opacity-70" />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <div
                ref={ref}
                className={`w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform shadow-xl bg-slate-800 rounded-2xl font-sans ${inter.variable}`}
              >
                <div className="flex flex-row items-center justify-between">
                  <h3 className="text-lg font-medium leading-6 text-blue-200">
                    Contributors
                  </h3>

                  <button
                    type="button"
                    className="text-blue-200 hover:text-blue-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    <XCircleIcon className="w-8 h-8" />
                  </button>
                </div>
                <div className="mt-2">
                  <p className="text-sm text-gray-200">
                    Thanks to everyone who has contributed to this doc!
                  </p>
                </div>

                <div className="max-h-[400px] overflow-y-auto overflow-x-hidden mt-4 gap-y-2 grid">
                  {allContributors.map((contributor: any) =>
                    contributorDiv(contributor)
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  ) : (
    <div></div>
  );
};

const contributorDiv = (contributor: User) => {
  return (
    <div
      key={contributor.username}
      className="flex flex-row items-center justify-between my-1"
    >
      <div className="flex flex-row items-center">
        <ExportedImage
          className="w-10 h-10 rounded-[50%]"
          src={`${constants.docsPath}/images/docs/authors/${contributor.image}`}
          alt="latest contributor to this doc"
          width={40}
          height={40}
          placeholder="empty"
          priority
        />
        <p className="flex flex-row items-center ml-4 text-lg text-zinc-600 dark:text-zinc-400">
          <a
            href={`https://github.com/${contributor.username}`}
            className="flex flex-row items-center ml-1 text-blue-500 underline hover:text-blue-300"
            target="_blank"
            rel="noreferrer"
          >
            @{contributor.username}
            <ArrowUpRightIcon className="w-4 h-4 ml-1" />
          </a>
        </p>
      </div>
      {/* <div className="flex flex-row items-center gap-2">
        {contributor.socials?.twitter && (
          <a
            href={`https://twitter.com/${contributor.socials.twitter}`}
            target="_blank"
            rel="noreferrer"
          >  <img src={twitter.src} alt="twitter icon"   className="w-6 h-6"/>
          </a>)}
          { contributor.socials?.linkedin && (  <a
            href={`${contributor.socials.linkedin}`}         
            target="_blank"
            rel="noreferrer"
          >  <img src={linkedin.src} alt="linkedin icon"  className="w-6 h-6 ml-4"/>
          </a>)}
      </div> */}
    </div>
  );
};
