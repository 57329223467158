type Procedure = (...args: any[]) => void;
let timeoutId: ReturnType<typeof setTimeout> | null = null;

export function debounceV2<T extends Procedure>(
  func: T,
  waitMilliseconds = 400
): T {
  const debouncedFunction = function (this: any, ...args: any[]) {
    const context = this;

    const doLater = function () {
      timeoutId = null;
      func.apply(context, args);
    };

    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(doLater, waitMilliseconds);
  };

  return debouncedFunction as T;
}
