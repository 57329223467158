import { CloseIcon, Logo, MenuIcon } from "@/components/icons/ElementIcons";
import { Dialog, DialogContent, DialogOverlay } from "@reach/dialog";
import { Fragment, useEffect, useRef, useState } from "react";
import { inter } from "@/styles/fonts";

import Link from "next/link";
import SuperNavDropdown from "@/components/shared/SuperNavDropdown";
import { XCircleIcon } from "@heroicons/react/24/outline";

const businessDropdownData = {
  title: "Business",
  items: [
    {
      title: "Business Home",
      link: "https://komodoplatform.com/en/business.html",
    },
    {
      title: "Komodo DeFi Framework",
      link: "https://komodoplatform.com/en/komodo-defi-framework.html",
    },
  ],
};

const developerDropdownData = {
  title: "Developer",
  items: [
    {
      title: "Developer Home",
      link: "https://komodoplatform.com/en/developer.html",
    },
    {
      title: "Developer docs",
      link: "https://komodoplatform.com/en/docs/",
    },
  ],
};
const communityDropdownData = {
  title: "Community",
  items: [
    {
      title: "Community Home",
      link: "https://komodoplatform.com/en/community.html",
    },
    {
      title: "Komodo Forum",
      link: "https://forum.komodoplatform.com",
    },
  ],
};
const exploreDropdownData = {
  title: "Explore",
  items: [
    {
      title: "Roadmap",
      link: "https://roadmap.komodoplatform.com",
    },
    {
      title: "Ecosystem",
      link: "https://komodoplatform.com/en/ecosystem.html",
    },
    {
      title: "Wallets",
      link: "https://komodoplatform.com/en/wallets.html",
    },
    {
      title: "Komodo Blog",
      link: "https://komodoplatform.com/en/blog/",
    },
    {
      title: "Komodo Academy",
      link: "https://komodoplatform.com/en/academy/",
    },
  ],
};
const langDropdownData = {
  title: "En",
  items: [
    {
      title: "English",
      link: "https://komodoplatform.com/en/",
    },
    {
      title: "Pусский",
      link: "https://blog.komodoplatform.com/ru/",
    },
    {
      title: "Español",
      link: "https://blog.komodoplatform.com/es/",
    },
  ],
};

export const SuperNav = () => {
  let [isSuperNavOpen, setIsSuperNavOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeModalIfClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", closeModalIfClickOutside);

    return () => {
      document.removeEventListener("mousedown", closeModalIfClickOutside);
    };
  }, []);

  function closeModal() {
    setIsSuperNavOpen(false);
  }
  function openModal() {
    setIsSuperNavOpen(true);
  }
  return (
    <header className="relative w-full text-white transition-all z-[10000] border-b-2 border-blue-400">
      <div
        className="container relative px-4 py-4 mx-auto transition-all md:py-9 lg:py-4 xs:px-8 sm:px-6 lg:px-8 xl:px-12"
        id="nav-padding-div"
      >
        <nav className="flex items-center justify-between w-full text-white">
          <div className="flex flex-row items-center">
            <Link
              href="https://komodoplatform.com/en/"
              aria-label="Komodo logo"
              className="mr-4"
            >
              <Logo />
            </Link>
            <SuperNavDropdown dropDownData={businessDropdownData} />
            <SuperNavDropdown dropDownData={developerDropdownData} />
            <SuperNavDropdown dropDownData={communityDropdownData} />
            <SuperNavDropdown dropDownData={exploreDropdownData} />
            <Link
              href="https://komodoplatform.com/en/contact.html"
              className="hidden px-2 py-2 mt-1 font-medium text-white hover:text-gray-300 lg:block"
            >
              Contact
            </Link>
          </div>
          <div className="flex-row items-center hidden lg:flex">
            <KomodoWalletBtn />
            {/* <NavDropdown dropDownData={langDropdownData} /> */}
          </div>
          <div className="block lg:hidden ">
            <button
              type="button"
              onClick={openModal}
              className="relative"
              aria-label="Open navigation"
            >
              <MenuIcon className="w-6 h-6 stroke-slate-500" />
            </button>
            <Dialog isOpen={isSuperNavOpen} onDismiss={closeModal}>
              <div className="">
                <div className="fixed inset-0 bg-black bg-opacity-70" />
                <div className="fixed inset-0 flex items-center justify-center">
                  <div
                    ref={ref}
                    className={`px-4 pt-4 pb-6 overflow-y-auto text-left align-middle transition-all transform shadow-xl bg-slate-900 rounded-2xl font-sans ${inter.variable} text-white w-[95%] max-h-[95%] overflow-y-auto`}
                  >
                    <div className="flex flex-row items-center justify-between">
                      <Link
                        href="https://komodoplatform.com/en/"
                        aria-label="Komodo logo"
                        className="mr-4"
                      >
                        <Logo />
                      </Link>

                      <button
                        type="button"
                        className="text-blue-200 hover:text-blue-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        <XCircleIcon className="w-8 h-8" />
                      </button>
                    </div>
                    <ul className="mt-2 space-y-3">
                      <MobileSuperNavItem
                        catName={"Business"}
                        items={businessDropdownData.items}
                      />
                      <MobileSuperNavItem
                        catName={"Developer"}
                        items={developerDropdownData.items}
                      />
                      <MobileSuperNavItem
                        catName={"Community"}
                        items={communityDropdownData.items}
                      />
                      <MobileSuperNavItem
                        catName={"Explore"}
                        items={exploreDropdownData.items}
                      />
                      <li className="pb-4">
                        <Link
                          href="https://komodoplatform.com/en/contact.html"
                          className="px-2 text-lg font-medium"
                        >
                          Contact
                        </Link>
                      </li>
                      <li>
                        <KomodoWalletBtn />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
        </nav>
      </div>
    </header>
  );
};

function KomodoWalletBtn() {
  return (
    <Link
      href="https://app.komodoplatform.com"
      className="px-3 py-2 mt-1 font-bold text-white rounded bg-brand-green hover:bg-brand-darker-green"
    >
      Komodo Wallet
    </Link>
  );
}

function MobileSuperNavItem({
  catName,
  items,
}: {
  catName: string;
  items: any;
}) {
  return (
    <li>
      <span className="text-lg font-medium text-blue-200">{catName}</span>
      <br />
      <ul className="mt-1">
        {items.map((item: any, index: number) => (
          <li key={index}>
            <Link
              href={item.link}
              target="_blank"
              rel="noreferrer"
              className="pl-2"
            >
              <span className="">{item.title}</span>
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
}
