import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/20/solid";
import { useEffect, useRef, useState } from "react";

import { Breadcrumbs } from "./Breadcrumb";
import { Contributors } from "./Contributors";
import { Footer } from "./Footer";
import { GitHubIcon } from "../icons/ElementIcons";
import { Hero } from "../homepage/Hero";
import { LeftSideBar } from "./LeftSideBar";
import Link from "next/link";
import { NavigationHeaderDesktop } from "./NavigationHeaderDesktop";
import { Prose } from "./Prose";
import { ScrollToTop } from "./ScrollToTop";
import StructuredData from "./StructuredData";
import { SuperNav } from "./SuperNav";
import authorsData from "authors.json";
import clsx from "clsx";
import { constants } from "constants.mjs";
import fileData from "_fileData.json";
import { inter } from "@/styles/fonts";
import { useHandleNavigation } from "@/store/navigation";
import { useRouter } from "next/router";
import { useTOC_Section_Indicator } from "@/hooks/useTOC_Section_Indicator";

const komodian_knight = {
  username: "komodoplatform",
  commit_emails: [],
  socials: {
    twitter: "komodoplatform",
    linkedin: "",
  },
  id: 0,
  avatar_url: "",
  image: "komodian-knight.png",
};

const komodian_knight_data = {
  name: "komodian_knight",
  username: komodian_knight.username,
  avatar_url: "",
  socials: komodian_knight.socials,
  image: komodian_knight.image,
};

// const allAuthors: Record<string, User> = authorsData;
const authorsArray = Object.values(authorsData);

export const Layout = ({
  children,
  pageProps,
  finTitle,
  finDesc,
  canonicalUrl,
  previewImgUrl,
}: {
  children: JSX.Element;
  pageProps: any;
  finTitle: string;
  finDesc: string;
  canonicalUrl: string;
  previewImgUrl: string;
}) => {
  pageProps?.description;
  //////////////////
  const router = useRouter();
  const route = router.pathname as keyof typeof fileData;
  const fileData_route = fileData[route];
  const allContributorsArray: any = fileData_route?.contributors
    ? fileData_route?.contributors.map((contributor) =>
        getAuthorData(contributor)
      )
    : [komodian_knight_data];
  const allContributors = allContributorsArray.filter(
    (item: any, index: number) => {
      //filter repeated entries
      return (
        index ===
        allContributorsArray.findIndex((obj: any) => {
          return obj.username === item.username;
        })
      );
    }
  );
  const lastContributor = fileData_route?.lastContributor
    ? getAuthorData(fileData_route?.lastContributor)
    : komodian_knight;

  ////////////////////////

  const isHomePage = router.pathname === "/";
  const activeSection = useTOC_Section_Indicator(pageProps.sections);

  const isActive = (id: string) => {
    if (id === activeSection) return true;
    else return false;
  };

  const { findAndSetNavigationDataForCurrentPage, navigationData } =
    useHandleNavigation();
  useEffect(() => {
    findAndSetNavigationDataForCurrentPage(router.pathname);
  }, [findAndSetNavigationDataForCurrentPage, router.pathname]);

  const techArticleStructuredData = {
    "@context": "https://schema.org",
    "@type": "TechArticle",
    headline: finTitle,
    description: finDesc,
    image: previewImgUrl,
    author: allContributors.map((contributor: any) => {
      return {
        "@type": "Person",
        name: contributor.username,
        url: `https://github.com/${contributor.username}`,
      };
    }),
    maintainer: {
      "@type": "Organization",
      name: constants.orgName,
      url: constants.domain,
      email: constants.supportEmail,
      logo: constants.domain + constants.pngLogo,
      sameAs: Object.values(constants.socialLinks),
      description: constants.orgDesc,
    },
    dateCreated: fileData_route ? fileData_route.dateCreated : "",
    dateModified: fileData_route ? fileData_route.dateModified : "",
  };

  const organizationStructuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: constants.orgName,
    // image: "", TODO:Find and use appropriate image
    url: constants.domain,
    email: constants.supportEmail,
    logo: constants.domain + constants.pngLogo,
    sameAs: Object.values(constants.socialLinks),
    description: constants.orgDesc,
  };

  const refOnThisPage = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeOnThisPageIfClickOutside = (event: MouseEvent) => {
      if (
        refOnThisPage.current &&
        !refOnThisPage.current.contains(event.target as Node)
      ) {
        setShowList(false);
      }
    };
    document.addEventListener("mousedown", closeOnThisPageIfClickOutside);

    return () => {
      document.removeEventListener("mousedown", closeOnThisPageIfClickOutside);
    };
  }, []);

  function flattenNavigation(navData: any) {
    let flatNav: any = [];
    navData.forEach((item: any) => {
      if (item.titleLink) {
        flatNav.push({ title: item.title, href: item.titleLink });
      }
      if (item.links && item.links.length > 0) {
        flatNav = flatNav.concat(item.links);
      }
    });
    return flatNav;
  }

  function getPrevNextPages(currentPath: string) {
    const flatNav = flattenNavigation(navigationData);
    const currentIndex = flatNav.findIndex(
      (item: any) => item.href === currentPath
    );

    if (currentIndex === -1) {
      return { prev: null, next: null };
    }

    const prevPage = currentIndex > 0 ? flatNav[currentIndex - 1] : null;
    const nextPage =
      currentIndex < flatNav.length - 1 ? flatNav[currentIndex + 1] : null;

    return { prevPage, nextPage };
  }
  const currentPath = router.pathname + "/";
  const { prevPage, nextPage } = getPrevNextPages(currentPath);
  const [showList, setShowList] = useState(false);

  const toggleList = () => {
    setShowList(!showList); // Toggle the visibility of the list
  };

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  /*
    const [fiveSecondsPassed, setFiveSecondsPassed] = useState(false); // State to toggle visibility of the list

  useEffect(() => {
    const timer = setTimeout(() => {
      setFiveSecondsPassed(true);
    }, 5000); // 5 seconds

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {

      //TODO: make right sidebar active item visible on pageload. test in http://localhost:3000/en/docs/antara/tutorials/advanced-series-5/

      let activeSectionLinkEl = document.getElementById(
        `link-${activeSection}`
      );

      let rightSidebarNav = document.getElementById("right-sidebar-nav");

      let rightSidebarDiv = document.getElementById("right-sidebar-div");

      let activeSectionLinkElTop =
        activeSectionLinkEl?.parentElement?.offsetTop;
      let rightSideBarHeight = rightSidebarDiv?.clientHeight;
      let sidebarFullHeight = rightSidebarNav?.scrollHeight;
      if (
        activeSectionLinkElTop &&
        rightSideBarHeight &&
        sidebarFullHeight &&
        rightSidebarDiv
      ) {
        rightSidebarDiv.scrollTop =
          activeSectionLinkElTop + rightSideBarHeight * 1.5 - sidebarFullHeight;
      }
    }
  }, [activeSection, fiveSecondsPassed]);*/

  // To make the long method names in the API methods page break at the end of table cell instead of overflowing
  useEffect(() => {
    const apiMethodsPath = "/komodo-defi-framework/api/";
    if (apiMethodsPath === currentPath) {
      const style = document.createElement("style");
      style.innerHTML = `
        table td {
          word-break: break-all;
        }
      `;
      document.head.appendChild(style);
      return () => {
        document.head.removeChild(style);
      };
    }
  }, [currentPath]);

  return (
    <div className={`w-full ${inter.variable} font-sans`}>
      <StructuredData
        data={techArticleStructuredData}
        customKey={"techarticle-structured-data"}
      />
      {/* This is redundant as the techarticle already contains the organization item<StructuredData
        data={organizationStructuredData}
        customKey={"organization-structured-data"}
      /> */}
      <SuperNav />
      <NavigationHeaderDesktop pageProps={pageProps} />
      {isHomePage && <Hero />}
      <div className="relative w-full">
        <div className="container flex px-4 mx-auto xs:px-8 sm:px-6 lg:px-8 xl:px-12">
          <div className="hidden lg:relative lg:block lg:flex-none">
            {/* <div className="absolute inset-y-0 right-0 w-[50vw] bg-slate-800" /> */}
            {/* <div className="absolute bottom-0 right-0 hidden w-px h-12 top-16 bg-gradient-to-t from-slate-800 dark:block" /> */}
            {/* <div className="absolute bottom-0 right-0 hidden w-px top-28 bg-slate-800 dark:block" /> */}
            <LeftSideBar
              isSidebarCollapsed={isSidebarCollapsed}
              setIsSidebarCollapsed={setIsSidebarCollapsed}
              activeSection={activeSection}
            />
          </div>
          {/* <div className="flex flex-col w-full mx-auto xl:flex-row lg:ml-auto lg:mr-0"> 
          2xlm:ml-[calc((1728px-18rem-800px-14rem)/2)] 3xl:ml-[calc((1920px-18rem-700px-14rem)/2)] 4xl:ml-[calc((2000px-18rem-800px-14rem)/2)]*/}

          <div
            className={`flex w-full flex-col xl:flex-row mx-auto lg:ml-auto lg:mr-0 xl:ml-auto ${
              isSidebarCollapsed ? "" : "xlm:ml-12 2xl:ml-12 3xl:ml-48"
            } `}
          >
            {pageProps?.sections?.length > 0 && (
              <div
                className="xl:hidden pl-4 lg:pl-8 sticky top-[58px] lg:top-[74px] z-[1000] w-full"
                ref={refOnThisPage}
              >
                <div className="overflow-x-hidden items-center rounded-lg dark:bg-slate-800 border border-[#08081c] pr-4 max-h-[65vh] overflow-y-auto">
                  <div className="flex flex-row items-center justify-between w-full py-1">
                    <div
                      className="flex flex-row items-center my-2 ml-4 cursor-pointer rounded-lg border-[#026782] border-2 p-1"
                      onClick={toggleList}
                    >
                      <h2 className="text-sm font-medium font-display text-slate-900 dark:text-white">
                        On this page
                      </h2>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        className="w-5 h-5 text-white "
                        aria-controls="smart-chain-setup-panel"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <Link
                      aria-label="Edit this page on github"
                      href={`${constants.contentRepo}/tree/dev/src/pages${
                        router.pathname === "/" ? "" : router.pathname
                      }/index.mdx`}
                      className="absolute right-4 bg-slate-800 rounded-lg border-[#026782] border-2 p-2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <GitHubIcon className="w-4 h-4 fill-slate-400 group-hover:fill-slate-500 dark:group-hover:fill-slate-400" />
                    </Link>
                  </div>
                  {showList && (
                    <ol
                      role="list"
                      className="mb-4 ml-4 space-y-3 text-sm break-words text-slate-500 dark:text-slate-400"
                    >
                      {pageProps?.sections.map(
                        (section: {
                          title: string;
                          id: string;
                          children: { title: string; id: string }[];
                        }) => (
                          <li key={section.id}>
                            <Link
                              href={`#${section.id}`}
                              scroll={false}
                              className={
                                isActive(section.id)
                                  ? "text-sky-500 text-[15px] break-words w-full"
                                  : " hover:text-slate-100 text-slate-300 text-[15px] break-words w-full"
                              }
                              onClick={() => setShowList(false)}
                            >
                              {section.title}
                            </Link>
                            {!!section.children.length && (
                              <ol
                                role="list"
                                className="pl-2 mt-2 ml-2 space-y-2 italic "
                              >
                                {section.children.map((link) => (
                                  <li key={link.id}>
                                    <Link
                                      href={`#${link.id}`}
                                      scroll={false}
                                      className={clsx(
                                        "flex flex-row w-full",
                                        isActive(link.id)
                                          ? "text-sky-500"
                                          : "hover:text-slate-100 text-slate-300"
                                      )}
                                      onClick={() => setShowList(false)}
                                    >
                                      <svg
                                        width="3"
                                        height="24"
                                        viewBox="0 -9 3 24"
                                        className="mr-1 overflow-visible 2xl:mr-2 group-hover:text-slate-600 dark:group-hover:text-slate-500"
                                      >
                                        <path
                                          d="M0 0L3 3L0 6"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                        ></path>
                                      </svg>
                                      <span className="max-w-full pr-2 break-words">
                                        {link.title}
                                      </span>
                                    </Link>
                                  </li>
                                ))}
                              </ol>
                            )}
                          </li>
                        )
                      )}
                    </ol>
                  )}
                </div>
              </div>
            )}
            <main
              className={`overflow-x-hidden flex flex-col min-w-0 px-4 py-16 pt-5 w-full ${
                isSidebarCollapsed
                  ? "lg:min-w-[706px] xl:min-w-[calc(1280px-288px-231px-48px-48px)] 2xl:min-w-[800px]"
                  : "lg:w-[706px] xl:w-[calc(1280px-288px-231px-48px-48px)] 2xl:w-[800px]"
              }  lg:pr-0 lg:pl-8 xl:px-8 2xl:px-16`}
            >
              <Breadcrumbs pageProps={pageProps} />

              <Contributors
                route={route}
                lastContributor={lastContributor}
                allContributors={allContributors}
                fileData_route={fileData_route}
              />
              <article className="pt-2 break-words">
                <Prose>{children}</Prose>
                <div className="flex flex-col pt-2 mt-10 text-xl text-white sm:flex-row">
                  {prevPage ? (
                    <Link
                      href={prevPage.href}
                      className="w-full sm:w-[45%] mr-auto"
                    >
                      <button className="flex flex-col items-center justify-start w-full h-full p-2 text-sm border-2 rounded-lg sm:items-start text-sky-500 border-sky-700 dark:text-blue-400 bg-slate-800 hover:bg-slate-600">
                        <span className="flex flex-row items-center text-white">
                          <ChevronDoubleLeftIcon className={`h-4 w-4 mr-1`} />
                          Previous
                        </span>
                        <span className="mt-2 text-xl text-center sm:text-left">
                          {prevPage.title}
                        </span>
                      </button>
                    </Link>
                  ) : (
                    ""
                  )}
                  {nextPage ? (
                    <Link
                      href={nextPage.href}
                      className="w-full sm:w-[45%] mt-4 sm:mt-0 ml-auto"
                    >
                      <button className="flex flex-col items-center justify-start w-full h-full p-2 text-sm border-2 rounded-lg sm:items-end text-sky-500 border-sky-700 dark:text-blue-400 bg-slate-800 hover:bg-slate-600">
                        <span className="flex flex-row items-center text-white">
                          Next
                          <ChevronDoubleRightIcon className={`h-4 w-4 ml-1`} />
                        </span>
                        <span className="mt-2 text-xl text-center sm:text-right">
                          {nextPage.title}
                        </span>
                      </button>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
                <Footer />
                <ScrollToTop />
              </article>
            </main>

            <div
              className="ml-4 relative xl:top-[7rem] hidden  xl:sticky xl:ml-0 xl:block xl:max-h-[calc(100vh-8.5rem)] xl:flex-none overflow-x-hidden xl:overflow-y-auto border-slate-700"
              id="right-sidebar-div"
            >
              <nav
                aria-labelledby="on-this-page-title"
                className={`relative xl:w-56 2xl:w-64 py-2 2xl:py-4 pl-2 pr-4 2xl:pl-4 rounded-lg ${
                  pageProps?.sections?.length > 0 ? "bg-slate-800" : ""
                }`}
                id="right-sidebar-nav"
              >
                <Link
                  aria-label="Edit this page on github"
                  href={`${constants.contentRepo}/tree/dev/src/pages${
                    router.pathname === "/" ? "" : router.pathname
                  }/index.mdx`}
                  className="flex flex-row items-center justify-between p-2 text-sm border-2 text-sky-500 border-sky-700 rounded-lg w-[120px] dark:text-blue-400 bg-slate-800 hover:bg-slate-600"
                  target="_blank"
                  rel="noreferrer"
                >
                  <GitHubIcon className="w-6 h-6 fill-slate-400 group-hover:fill-slate-500 dark:group-hover:fill-slate-400" />
                  Edit page
                </Link>
                {pageProps?.sections?.length > 0 && (
                  <>
                    <h2
                      id="on-this-page-title"
                      className="mt-4 text-sm font-medium font-display text-slate-900 dark:text-white"
                    >
                      On this page
                    </h2>
                    <ol
                      role="list"
                      className="mt-4 space-y-3 text-sm break-words text-slate-500 dark:text-slate-400"
                    >
                      {pageProps?.sections.map(
                        (section: {
                          title: string;
                          id: string;
                          children: { title: string; id: string }[];
                        }) => (
                          <li key={section.id}>
                            <Link
                              href={`#${section.id}`}
                              scroll={false}
                              className={
                                isActive(section.id)
                                  ? "text-sky-500 text-[15px] break-words w-full"
                                  : " hover:text-slate-100 text-slate-300 text-[15px] break-words w-full"
                              }
                              id={`link-${section.id}`}
                            >
                              {section.title}
                            </Link>
                            {!!section.children.length && (
                              <ol
                                role="list"
                                className="pl-2 mt-2 space-y-2 italic "
                              >
                                {section.children.map((link) => (
                                  <li key={link.id}>
                                    <Link
                                      href={`#${link.id}`}
                                      scroll={false}
                                      className={clsx(
                                        " flex flex-row w-full",
                                        isActive(link.id)
                                          ? "text-sky-500"
                                          : "hover:text-slate-100 text-slate-300"
                                      )}
                                      id={`link-${link.id}`}
                                    >
                                      <svg
                                        width="3"
                                        height="24"
                                        viewBox="0 -9 3 24"
                                        className="overflow-visible xl:mr-1 2xl:mr-2 group-hover:text-slate-600 dark:group-hover:text-slate-500"
                                      >
                                        <path
                                          d="M0 0L3 3L0 6"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                        ></path>
                                      </svg>
                                      <span className="max-w-full pr-2 break-words">
                                        {link.title}
                                      </span>
                                    </Link>
                                  </li>
                                ))}
                              </ol>
                            )}
                          </li>
                        )
                      )}
                    </ol>
                  </>
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function getAuthorData({ name, email }: { name: string; email: string }) {
  const filteredAuthorInfo = authorsArray.filter((authorData) => {
    return authorData.commit_emails.some(
      (authorEmail) => authorEmail === email
    );
  });
  const authorInfo =
    filteredAuthorInfo.length > 0 ? filteredAuthorInfo[0] : komodian_knight;
  return {
    name: name,
    username: authorInfo.username,
    avatar_url: authorInfo.avatar_url,
    socials: authorInfo.socials,
    image: authorInfo.image,
  };
}
