interface ScrollToOptions {
  top: number;
  left: number;
  behavior?: "auto" | "smooth";
}

export const scrollToSection = ({
  top,
  left,
  behavior = "smooth",
}: ScrollToOptions) => {
  window.scrollTo({ top, left, behavior });
};
