import {
  ArrowIconWithDir,
  CheckIcon,
  DiscordIcon,
  GitHubIcon,
  OpenNewTabIcon,
  TwitterIcon,
} from "../icons/ElementIcons";
import { ButtonHTMLAttributes, SVGAttributes, useState } from "react";

import Link from "next/link";
import { constants } from "constants.mjs";
import fileData from "_fileData.json";
import { humanizeTime } from "@/shared-functions/timeAgo";
import { useHandleNavigation } from "@/store/navigation";
import { useRouter } from "next/router";

const FeedbackButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      type="submit"
      className="px-3 text-sm font-medium text-zinc-600 transition hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-zinc-400 dark:hover:bg-white/5 dark:hover:text-white"
      {...props}
    />
  );
};

const FeedbackForm = () => {
  const router = useRouter();
  const modifiedDateIndexer = router.pathname as keyof typeof fileData;
  return (
    <div className="flex items-center -sm:flex-col">
      {/* <div>
        <form
          onSubmit={(e) => e.preventDefault()}
          className="flex items-center justify-center gap-6 md:justify-start"
        >
          <p className="text-sm text-zinc-600 dark:text-zinc-400">
            Was this page helpful?
          </p>
          <div className="group grid grid-cols-[1fr,1px,1fr] overflow-hidden rounded-full border border-zinc-900/10 dark:border-white/10">
            <FeedbackButton data-response="yes">Yes</FeedbackButton>
            <div className="bg-zinc-900/10 dark:bg-white/10" />
            <FeedbackButton data-response="no">No</FeedbackButton>
          </div>
        </form>
      </div> */}
      {/* <Link
        ariel-aria-label="Edit this page on github"
        href={`${constants.contentRepo}/src/pages${
          router.pathname === "/" ? "" : router.pathname
        }/index.mdx`}
        className="flex flex-row items-center text-sm text-zinc-600 dark:text-zinc-400 -sm:mt-6 -sm:text-right -sm:block -sm:w-full"
        target="_blank"
        rel="noreferrer"
      >
        Suggest an improvement for this page{" "} <OpenNewTabIcon />
      </Link> */}

      {/* {fileData[modifiedDateIndexer].dateModified && (
        <div className="flex justify-end mt-4 text-xs text-zinc-600 dark:text-zinc-400">
          <time dateTime={fileData[modifiedDateIndexer].dateModified}>
            {`Last Updated: ${humanizeTime(
              fileData[modifiedDateIndexer].dateModified
            )}`}
          </time>
        </div>
      )} */}
    </div>
  );
};

const FeedbackThanks = () => {
  return (
    <div className="absolute inset-0 flex justify-center md:justify-start">
      <div className="flex items-center gap-3 rounded-full bg-emerald-50/50 py-1 pr-3 pl-1.5 text-sm text-emerald-900 ring-1 ring-inset ring-emerald-500/20 dark:bg-emerald-500/5 dark:text-emerald-200 dark:ring-emerald-500/30">
        <CheckIcon className="flex-none w-5 h-5 fill-emerald-500 stroke-white dark:fill-emerald-200/20 dark:stroke-emerald-200" />
        Thanks for your feedback!
      </div>
    </div>
  );
};

const Feedback = () => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <div className="relative mt-10">
      {/* <Transition
        show={!submitted}
        as={Fragment}
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        leave="pointer-events-none duration-300"
      > */}
      <FeedbackForm />
      {/* </Transition> */}
      {/* <Transition
        show={submitted}
        as={Fragment}
        enterFrom="opacity-0"
        enterTo="opacity-100"
        enter="delay-150 duration-300"
      > */}
      {/* <FeedbackThanks /> */}
      {/* </Transition> */}
    </div>
  );
};

const PageLink = ({
  label,
  page,
  previous = false,
}: {
  label: string;
  page: { href: string; title: string };
  previous?: boolean;
}) => {
  return (
    <>
      {/* <Button2
        href={page.href}
        aria-label={`${label}: ${page.title}`}
        variant="secondary"
        arrow={previous ? "left" : "right"}
      >
        {label}
      </Button2> */}

      <Link
        href={page.href}
        rel={previous ? "prev" : "next"}
        ariel-aria-label={`Navigate to ${page.title}`}
        className="text-base p-2 flex items-center gap-1 font-semibold text-zinc-900 transition hover:text-sky-400 dark:text-white dark:rounded-[0.625rem] dark:hover:bg-slate-900/40"
      >
        {previous !== undefined && previous && (
          <ArrowIconWithDir direction={"left"} />
        )}
        {page.title}
        {previous !== undefined && !previous && (
          <ArrowIconWithDir direction={"right"} />
        )}
      </Link>
    </>
  );
};

const PageNavigation = () => {
  const router = useRouter();
  const currentPath = router.pathname;
  const { navigationData } = useHandleNavigation();
  const allPages = navigationData.flatMap((group) =>
    group.titleLink
      ? [
          {
            title: group.title,
            href: group.titleLink,
          },
        ]
      : group.links
  );

  const currentPageIndex = allPages.findIndex(
    (page) => page.href === currentPath
  );

  if (currentPageIndex === -1) {
    return null;
  }

  const previousPage = allPages[currentPageIndex - 1];
  const nextPage = allPages[currentPageIndex + 1];

  if (!previousPage && !nextPage) {
    return null;
  }

  return (
    <div className="flex mt-20">
      {previousPage && (
        <div className="flex flex-col items-start gap-3">
          <PageLink label="Previous" page={previousPage} previous />
        </div>
      )}
      {nextPage && (
        <div className="flex flex-col items-end gap-3 ml-auto">
          <PageLink label="Next" page={nextPage} />
        </div>
      )}
    </div>
  );
};

const SocialLink = ({
  href,
  Icon,
  children,
}: {
  href: string;
  Icon: (props: SVGAttributes<SVGSVGElement>) => JSX.Element;
  children: string;
}) => {
  return (
    <Link href={href} className="group">
      <span className="sr-only">{children}</span>
      <Icon className="w-5 h-5 transition fill-zinc-700 group-hover:fill-zinc-900 dark:group-hover:fill-zinc-500" />
    </Link>
  );
};

const SmallPrint = () => {
  return (
    <div className="flex flex-col items-center justify-between gap-5 pt-8 border-t border-zinc-900/5 dark:border-white/5 sm:flex-row">
      <p className="text-xs text-zinc-600 dark:text-zinc-400">
        &copy; 2016 - {new Date().getFullYear()} by Komodo Platform.
      </p>
      <div className="flex gap-4">
        <SocialLink href={constants.socialLinks.twitter} Icon={TwitterIcon}>
          Follow us on Twitter
        </SocialLink>
        <SocialLink href={constants.socialLinks.github} Icon={GitHubIcon}>
          Follow us on GitHub
        </SocialLink>
        <SocialLink href={constants.socialLinks.discord} Icon={DiscordIcon}>
          Join our Discord server
        </SocialLink>
      </div>
    </div>
  );
};

export const Footer = () => {
  const router = useRouter();
  return (
    <footer className="max-w-2xl mx-auto space-y-10 lg:max-w-5xl">
      <Feedback key={router.pathname} />
      {/* <PageNavigation /> */}
      <SmallPrint />
    </footer>
  );
};
