import { SidebarNavigationDataType, SidebarNavigationRouteType } from "./types";
import { atom, useRecoilState } from "recoil";

import { atomKeys } from "../atomKeys";
import { sidebarNavData } from "@/data/sidebar";
import { useCallback } from "react";

const docsNavigationState = atom<SidebarNavigationDataType[]>({
  key: atomKeys.docsNavigation,
  default: [],
});

function compareTitleLinksAndHrefs(
  obj: SidebarNavigationRouteType,
  currentRoute: string
) {
  for (const key in obj) {
    const categories = obj[key];
    for (const category of categories) {
      if (category.titleLink) {
        if (category.titleLink === currentRoute + "/") {
          return true;
        }
      }
      if (category.links) {
        for (const link of category.links) {
          if (link.href === currentRoute + "/") {
            return true;
          }
        }
      }
    }
  }
  return false;
}

export const useHandleNavigation = () => {
  const [navigationData, setNavigationData] =
    useRecoilState(docsNavigationState);
  const findAndSetNavigationDataForCurrentPage = useCallback(
    (pageRoute: string) => {
      sidebarNavData.forEach((sidebarObj: SidebarNavigationRouteType) => {
        if (sidebarObj.hasOwnProperty("/start-here/")) {
          setNavigationData(Object.values(sidebarObj)[0]);
        }
      });

      sidebarNavData.forEach((sidebarObj: SidebarNavigationRouteType) => {
        if (compareTitleLinksAndHrefs(sidebarObj, pageRoute)) {
          setNavigationData(Object.values(sidebarObj)[0]);
        }
      });
    },
    [setNavigationData]
  );

  return {
    navigationData,
    findAndSetNavigationDataForCurrentPage,
  };
};
